import { ENDPOINT_WITH_FILTERS, FilterParamsCommonDto } from '@appTypes/models/common.dto';
import {
  CreateSiteDto,
  ExportDataPayload,
  SiteDto,
  SiteOptimizationDto,
  SitesDto,
  UpdateOptimizationPayload,
  UpdateSiteDto,
  UpdateSiteUserDto,
} from '@appTypes/models/site.dto';
import { createEndpointQueryForAllItems, createParams } from '@utils/utils';
import { coreApi, extraParams } from './core';

export type SelectOptions = {
  id: number;
  name: string;
}[];

export const sitesApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSite: builder.query<SiteDto, number>({
      query: (siteId) => `sites/${siteId}`,
      providesTags: (result, error, siteId) => [{ type: 'Site', id: siteId }],
    }),
    getSites: builder.query<SitesDto, FilterParamsCommonDto>({
      query: ({ ...params }) => `sites?${createParams(params)}`,
      providesTags: ['Sites'],
    }),
    getSitesAll: builder.query<SitesDto, void>({
      query: () => createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.SITES),
      providesTags: ['SitesAll'],
    }),
    getSitesAllForSelect: builder.query<SelectOptions, void>({
      query: () => `${createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.SITES)}&order_by=name`,
      transformResponse: (response: SitesDto) => {
        if (!response.sites) return [];

        const sites = [...response.sites].map(({ id, name }) => ({
          id,
          name,
        }));

        return sites;
      },
    }),
    export: builder.mutation<{}, ExportDataPayload>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}/report-export?${createParams(extraParams)}`,
        method: 'POST',
        data: {
          ...payload,
        },
      }),
    }),
    addSiteRelatedUser: builder.mutation<SiteDto, UpdateSiteUserDto>({
      query: ({ id, ...payload }) => ({
        url: `sites/${id}/users`,
        method: 'POST',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: (result, error, { id, user_id }) => [
        { type: 'Site', id },
        { type: 'User', id: user_id },
        'Sites',
        'Users',
      ],
    }),
    createSite: builder.mutation<SiteDto, CreateSiteDto>({
      query: ({ ...payload }) => ({
        url: 'sites',
        method: 'POST',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: ['Sites', 'SitesAll'],
    }),
    updateSite: builder.mutation<SiteDto, UpdateSiteDto>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}`,
        method: 'PATCH',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: (result, error, { siteId }) => [
        { type: 'Site', id: siteId },
        'Sites',
        'SitesAll',
      ],
    }),
    getSiteOptimization: builder.query<SiteOptimizationDto, SiteDto['id']>({
      query: (siteId) => `sites/${siteId}/optimization`,
      providesTags: (result, error, siteId) => [{ type: 'SiteOptimization', id: siteId }],
    }),
    updateSiteOptimization: builder.mutation<string, UpdateOptimizationPayload>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}/optimization`,
        method: 'PATCH',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: (result, error, { siteId }) => [
        { type: 'SiteOptimization', id: siteId },
        { type: 'PerformanceOverTime', id: siteId },
        'Sites',
        'SitesAll',
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddSiteRelatedUserMutation,
  useGetSiteQuery,
  useGetSitesQuery,
  useGetSitesAllQuery,
  useGetSitesAllForSelectQuery,
  useGetSiteOptimizationQuery,
  useUpdateSiteOptimizationMutation,
  useExportMutation,
  useCreateSiteMutation,
  useUpdateSiteMutation,
} = sitesApi;
