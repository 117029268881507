import { SiteDto } from '@appTypes/models/site.dto';
import { UserRole, UserSimpleDto } from '@appTypes/models/user.dto';
import { ButtonAdd } from '@components/Buttons/Buttons';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect, useFilters } from '@hooks';
import { useDataManipulation } from '@hooks/useDataManipulation';
import { Box, Button } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';
import { createFullName } from '@utils/data/createFullName';
import { normaliseString } from '@utils/utils';

type UsersGridCellProps = {
  roleName: string;
  id: number;
};

const CellWithRedirectButton = ({ roleName, id }: UsersGridCellProps) => {
  const redirect = useRedirect();

  const roleDisplay = (() => {
    if (roleName === UserRole.ADMIN) {
      return 'manager';
    }

    if (roleName === UserRole.USER) {
      return 'technician';
    }

    return roleName;
  })();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '220px',
      }}
    >
      <span>{roleDisplay}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationUser(id))}>
        User detail
      </Button>
    </Box>
  );
};

const columns: ColumnDef<UserSimpleDto>[] = [
  {
    header: 'User',
    accessorKey: 'last_name',
    enableSorting: false,
    cell: ({ row }) => (
      <div
        style={{
          flex: '0 0 auto',
          minWidth: '150px',
          maxWidth: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div style={{ fontWeight: 'bold' }}>
          {createFullName(row.original.first_name, row.original.last_name)}
        </div>
        <div>{row.original.email}</div>
      </div>
    ),
  },
  {
    header: 'Role',
    accessorKey: 'role',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: '0 0 auto' }}>
        <CellWithRedirectButton roleName={row.original.role.name} id={row.original.id} />
      </div>
    ),
  },
];

interface UserListProps {
  siteData?: SiteDto;
  onAddRelatedUser: () => void;
}

const UserList = ({ siteData, onAddRelatedUser }: UserListProps) => {
  const { sorting, searchString, pagination, setSorting, setSearchString, setPagination } =
    useFilters({
      enableUrlMirroring: false,
      enableSorting: false,
      pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE,
      sortingState: [],
    });

  const { data, totalItems } = useDataManipulation<UserSimpleDto>({
    data: siteData?.users || [],
    searchString,
    sorting,
    pagination,
    getSearchableString: (user) =>
      normaliseString([user.first_name, user.last_name, user.email].join(' ')),
    getSortableValue: (user, sortId) => user[sortId as keyof UserSimpleDto],
    enableSorting: false,
  });

  return (
    <>
      <ButtonAdd sx={{ my: 2 }} onClick={onAddRelatedUser}>
        Add related user
      </ButtonAdd>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<UserSimpleDto>
        columns={columns}
        rows={data}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default UserList;
