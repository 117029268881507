import { useEffect } from 'react';
import { useAddSiteRelatedUserMutation } from '@apiRtk/sites';
import { EcdDevicesPermissions } from '@appTypes/models/site.dto';
import SiteSelect from '@components/Form/SiteSelect';
import { GapY } from '@components/LayoutUtils';
import FormModal from '@components/Modals/FormModal';
import { Select, MenuItem, Alert } from '@mui/material';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { dictionary } from '@settings/dictionary';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const ecdDevicesPermissionsItems = [
  { value: EcdDevicesPermissions.VIEW, label: 'view' },
  {
    value: EcdDevicesPermissions.BASIC_EDIT,
    label: 'basic edit',
  },
  {
    value: EcdDevicesPermissions.ADVANCED_EDIT,
    label: 'advanced edit',
  },
];

type FormValues = {
  siteId: Nullable<number>;
  permissions: EcdDevicesPermissions;
};

const defaultValues = {
  siteId: null,
  permissions: EcdDevicesPermissions.VIEW,
};

export const SiteRelationModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [addUserToSite, { isLoading, isSuccess, isError }] = useAddSiteRelatedUserMutation();
  const params = useParams();
  const userId = parseInt(params.id!, 10);

  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, formState, reset } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      dispatch(
        createAlert({
          message: 'User was successfully added',
          variant: 'success',
        }),
      );
      reset();
    }
  }, [dispatch, isSuccess, reset, setOpen, userId]);

  const handleFormSubmit: SubmitHandler<FormValues> = (fieldValues) => {
    const { siteId, permissions } = fieldValues;

    if (!siteId) {
      return;
    }

    addUserToSite({
      id: siteId,
      user_id: userId,
      devices_permissions: permissions,
    });
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle="Add related site"
      onSubmit={handleSubmit(handleFormSubmit)}
      submitDisabled={!formState.isValid || isLoading}
    >
      {isError ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {dictionary.errorAddUserToSite}
        </Alert>
      ) : null}

      <SiteSelect userId={userId} name="siteId" control={control} setValue={setValue} required />

      <GapY size={2} />

      <Controller
        name="permissions"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            size="medium"
            label="ecd devices permissions"
            value={value || ''}
            fullWidth
            onChange={onChange}
            sx={{ minWidth: '400px' }}
          >
            {ecdDevicesPermissionsItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormModal>
  );
};
