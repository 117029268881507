// TODO: Populate this dictionary with all commonly used error messages, titles, and other text strings across the application.
// This centralizes content management, making it easier to maintain consistency.
// Note: In the future, this dictionary can be replaced or extended with a more sophisticated translation solution.

export const dictionary = {
  errorAddEcdToSite: 'Some error occured while adding ECD to site.',
  errorAddUserToSite: 'Some error occured while adding user to site.',
  errorAppBehindFirewall:
    'Podium application was not able to contact our servers, this can be caused by firewall, please try open Podium from another network.',
  errorCommon: 'Some unexpected error occured, please try again later.',
  errorCommonAction:
    'We encountered an error while processing your request. Please try again later.',
  errorGraphCommon: 'An issue occurred while retrieving graph data.',
  errorGraphDrawing:
    'An error occurred while drawing the graph. If the problem persists, contact the application vendor.',
  errorNoData: 'No data available. Please try again later.',
  errorNoEcdFound: 'No ECD found for this site. Please contact support.',
  errorOffline: 'You are currently offline. Please check your internet connection.',
  seriesNoDataInfo: 'No data available in this timeframe',
  errorBookmarkLimitReached: (name: string, maximumCapacity: number) =>
    `Cannot bookmark site ${name}. Maximum capacity of ${maximumCapacity} sites reached.`,
  errorSiteAlreadyBookmarked: (name: string) => `Site ${name} is already bookmarked.`,
  errorSiteNameNotBookmarked: (name: string) => `Site ${name} is not bookmarked.`,
  errorSiteNotBookmarked: 'Site is not bookmarked.',
  successSiteBookmarked: (name: string) => `Site ${name} successfully bookmarked.`,
  successSiteUnbookmarked: (name: string) => `Site ${name} successfully unbookmarked.`,
  selectedSiteNotFound: 'Selected site was not found.',
  preparingGraphData: 'Preparing graph data',
  successSiteOptimizationUpdate: 'Site optimization successfully updated.',
  validationOrganizationIdRequired: 'Organization is required',
  validationOrganizationIdInvalidType: 'Please select organization',
  validationEmailRequired: 'Email is required',
  validationEmailInvalidType: 'Email must be a string',
  validationFirstNameRequired: 'First Name is required',
  validationFirstNameInvalidType: 'First Name must be a string',
  validationFirstNameInvalidCharacters: 'First Name must not contain special characters.',
  validationFirstNameMinLength: 'First Name must contain at least 2 characters',
  validationFirstNameMaxLength: 'First Name must contain at most 50 characters',
  validationLastNameRequired: 'Last Name is required',
  validationLastNameInvalidType: 'Last Name must be a string',
  validationLastNameInvalidCharacters: 'Last Name must not contain special characters.',
  validationLastNameMinLength: 'Last Name must contain at least 2 characters',
  validationLastNameMaxLength: 'Last Name must contain at most 50 characters',
  validationPhoneRequired: 'Phone is required',
  validationPhoneInvalidType: 'Phone must be a string',
  validationPhoneInvalidCharacters: 'Phone must be valid',
  validationPhoneMinLength: 'Phone must contain at least 4 characters',
  validationPhoneMaxLength: 'Phone must contain at most 20 characters',
  validationRoleNameRequired: 'Role Name is required',
  validationRoleNameInvalidType: 'Role Name must be a valid UserRole',
};

export type DictionaryKeys = keyof typeof dictionary;
