import { useGetOrganizationQuery } from '@apiRtk/organizations';
import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useFilters, useRedirect } from '@hooks';
import { useDataManipulation } from '@hooks/useDataManipulation';
import { Box, Button } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';
import { normaliseString } from '@utils/utils';

type SitesGridCellProps = {
  name: string;
  id: number;
};

const CellWithRedirectButton = ({ name, id }: SitesGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{name}</span>
      <Button
        variant="outlined"
        onClick={() => {
          redirect((paths) => paths.configurationSite(id));
        }}
      >
        Site detail
      </Button>
    </Box>
  );
};

const columns: ColumnDef<SiteSimpleDto>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1 }}>
        <CellWithRedirectButton name={row.original.name} id={row.original.id} />
      </div>
    ),
  },
];

interface SitesRelationsProps {
  organizationId: number;
}

export const SitesRelations = ({ organizationId }: SitesRelationsProps) => {
  const { data } = useGetOrganizationQuery(organizationId);

  const { sorting, searchString, pagination, setSearchString, setPagination } = useFilters({
    enableUrlMirroring: false,
    enableSorting: false,
    pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE_SMALL,
    sortingState: [],
  });

  const { data: paginatedData, totalItems } = useDataManipulation<SiteSimpleDto>({
    data: data?.sites || [],
    searchString,
    sorting,
    pagination,
    getSearchableString: (site) => normaliseString(site.name),
    getSortableValue: (site, sortId) => site[sortId as keyof SiteSimpleDto],
    enableSorting: false,
  });

  return (
    <>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<SiteSimpleDto>
        columns={columns}
        rows={paginatedData}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={() => {}}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};
