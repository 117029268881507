import { useEffect, useState } from 'react';
import { useGetOrganizationsAllForSelectQuery } from '@apiRtk/organizations';
import { useUpdateUserMutation } from '@apiRtk/users';
import { ApiErrorPayload } from '@appTypes/api/error';
import { UserDto, UserRole } from '@appTypes/models/user.dto';
import AutocloseAlert from '@components/AutocloseAlert/AutocloseAlert';
import { ButtonCancel, ButtonEdit, ButtonSave } from '@components/Buttons/Buttons';
import { Flex, GapY } from '@components/LayoutUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import FormViewMode from '../Site/FormViewMode';
import { userFormSchema } from './UserFormSchema';

const defaultValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role_name: UserRole.USER,
  organization_id: 0,
};

type FormValues = z.infer<typeof userFormSchema>;

interface UpdateUserFormProps {
  userId: number;
  userData?: UserDto;
}

const UpdateUserForm = ({ userId, userData }: UpdateUserFormProps) => {
  const { data: organizations, isLoading: isOrganizationsLoading } =
    useGetOrganizationsAllForSelectQuery();

  const [updateUser, { isLoading, isSuccess, isError, error }] = useUpdateUserMutation();
  const typedError = error as ApiErrorPayload | undefined;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(userFormSchema),
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateUser({
      id: userId,
      ...data,
      role: data.role_name,
      organization_id: data.organization_id === 0 ? null : data.organization_id,
    }).then(() => {
      setIsEditMode(false);
    });
  };

  useEffect(() => {
    if (!userData) {
      return;
    }
    reset({
      email: userData.email,
      first_name: userData.first_name || '',
      last_name: userData.last_name || '',
      phone: userData.phone || '',
      role_name: userData.role?.name as UserRole,
      organization_id: userData.organization?.id,
    });
  }, [userData, reset]);

  if (isOrganizationsLoading) {
    return <CircularProgress />;
  }

  const fields = userData
    ? [
        { label: 'E-mail', value: userData.email },
        { label: 'First name', value: userData.first_name },
        { label: 'Last name', value: userData.last_name },
        { label: 'Phone', value: userData.phone },
        { label: 'Role', value: userData.role?.name },
        { label: 'Organization', value: userData.organization?.name },
      ]
    : [];

  const userRoles = [
    {
      id: UserRole.SUPERADMIN,
      name: UserRole.SUPERADMIN,
    },
    {
      id: UserRole.ADMIN,
      name: UserRole.ADMIN,
    },
    {
      id: UserRole.USER,
      name: UserRole.USER,
    },
  ];

  return (
    <Box>
      {isEditMode ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Email"
                value={value}
                error={!!errors.email}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled
              />
            )}
          />
          {errors.email && (
            <Typography variant="subtitle2" color="error">
              {errors.email.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="First Name"
                value={value}
                error={!!errors.first_name}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.first_name && (
            <Typography variant="subtitle2" color="error">
              {errors.first_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Last Name"
                value={value}
                error={!!errors.last_name}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.last_name && (
            <Typography variant="subtitle2" color="error">
              {errors.last_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Phone"
                value={value}
                error={!!errors.phone}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.phone && (
            <Typography variant="subtitle2" color="error">
              {errors.phone.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="role_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Role"
                sx={{ width: '100%' }}
                value={value}
                error={!!errors.role_name}
                onChange={(event) => onChange(event.target.value as UserRole)}
                disabled={!isEditMode}
              >
                {userRoles.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.role_name && (
            <Typography variant="subtitle2" color="error">
              {errors.role_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="organization_id"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Organization"
                sx={{ width: '100%' }}
                value={value}
                error={!!errors.organization_id}
                onChange={onChange}
                disabled={!isEditMode}
              >
                <MenuItem key={0} value={0}>
                  -
                </MenuItem>
                {(organizations || []).map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.organization_id && (
            <Typography variant="subtitle2" color="error">
              {errors.organization_id.message}
            </Typography>
          )}

          <GapY size={2} />

          <Flex gap={1}>
            <ButtonSave size="large" type="submit" disabled={isLoading} />
            <ButtonCancel onClick={() => setIsEditMode(false)} disabled={isLoading} />
          </Flex>
        </form>
      ) : (
        <Box>
          {userData && <FormViewMode fields={fields} />}
          <Flex justifyContent="right">
            <ButtonEdit onClick={() => setIsEditMode(true)} />
          </Flex>
        </Box>
      )}
      {isError && (
        <>
          <Alert severity="error">
            {typedError && typeof typedError?.data?.detail === 'string'
              ? typedError.data.detail
              : 'An error occurred'}
          </Alert>
          <GapY size={2} />
        </>
      )}
      {isSuccess && <AutocloseAlert severity="success" message="Saved" sx={{ marginTop: 2 }} />}
    </Box>
  );
};

export default UpdateUserForm;
