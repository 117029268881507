import { EcdSimpleDto } from '@appTypes/models/ecd.dto';
import { SiteDto } from '@appTypes/models/site.dto';
import { ButtonAdd, ButtonDetail } from '@components/Buttons/Buttons';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useFilters, useRedirect } from '@hooks';
import { useDataManipulation } from '@hooks/useDataManipulation';
import { Box } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';
import { normaliseString } from '@utils/utils';

type ECDsGridCellProps = {
  name: string;
  id: string;
};

const CellWithRedirectButton = ({ name, id }: ECDsGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{name}</span>
      <ButtonDetail onClick={() => redirect((paths) => paths.configurationEcd(id))} />
    </Box>
  );
};

const columns: ColumnDef<EcdSimpleDto>[] = [
  {
    header: 'ECD ID',
    accessorKey: 'id',
    enableSorting: false,
  },
  {
    header: 'ECD Name',
    accessorKey: 'name',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ minWidth: 220, flex: '1' }}>
        <CellWithRedirectButton name={row.original.name} id={row.original.id} />
      </div>
    ),
  },
];

interface EcdListProps {
  siteData?: SiteDto;
  onAddRelatedECDs: () => void;
}

const EcdList = ({ siteData, onAddRelatedECDs }: EcdListProps) => {
  const { sorting, searchString, pagination, setSorting, setSearchString, setPagination } =
    useFilters({
      enableUrlMirroring: false,
      enableSorting: false,
      pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE,
      sortingState: [],
    });

  const { data, totalItems } = useDataManipulation<EcdSimpleDto>({
    data: siteData?.imps || [],
    searchString,
    sorting,
    pagination,
    getSearchableString: (ecd) => normaliseString([ecd.id, ecd.name].join()),
    getSortableValue: (ecd, sortId) => ecd[sortId as keyof EcdSimpleDto],
    enableSorting: false,
  });

  return (
    <>
      <ButtonAdd sx={{ my: 2 }} onClick={onAddRelatedECDs}>
        Add related ECD
      </ButtonAdd>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<EcdSimpleDto>
        columns={columns}
        rows={data}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default EcdList;
