import { SortingState } from '@tanstack/react-table';
import { normaliseString } from '@utils/utils';

interface UseDataManipulationProps<T> {
  data: T[];
  searchString: string;
  sorting: SortingState;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  enableSorting?: boolean;
  getSearchableString: (item: T) => string;
  getSortableValue: (item: T, sortId: string) => any;
}

export const useDataManipulation = <T>({
  data,
  searchString,
  sorting,
  pagination,
  enableSorting = true,
  getSearchableString,
  getSortableValue,
}: UseDataManipulationProps<T>) => {
  const filteredData = data.filter((item) =>
    normaliseString(getSearchableString(item)).includes(normaliseString(searchString)),
  );

  const sortedData = (() => {
    if (!enableSorting || sorting.length === 0) return filteredData;

    const sort = sorting[0];
    const sortId = sort?.id;
    if (!sortId) return filteredData;

    const isDesc = sort?.desc ? -1 : 1;

    return [...filteredData].sort((a, b) => {
      const aValue = getSortableValue(a, sortId);
      const bValue = getSortableValue(b, sortId);

      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return 1;
      if (bValue == null) return -1;

      if (aValue > bValue) return isDesc;
      if (aValue < bValue) return -isDesc;
      return 0;
    });
  })();

  const paginatedData = sortedData.slice(
    pagination.pageIndex * pagination.pageSize,
    (pagination.pageIndex + 1) * pagination.pageSize,
  );

  return {
    data: paginatedData,
    totalItems: filteredData.length,
  };
};
