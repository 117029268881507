import { ReactNode, useState } from 'react';
import { useGetHeadersQuery } from '@apiRtk/headers';
import { HeadersResponse } from '@appTypes/models/headers.dto';
import { UserRole } from '@appTypes/models/user.dto';
import { ButtonAdd } from '@components/Buttons/Buttons';
import ConfigurationTabs from '@components/ConfigurationTabs/ConfigurationTabs';
import { Flex } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { useCurrentRole, useFilters, useRedirect } from '@hooks';
import { Box, Grid } from '@mui/material';
import {
  ConfigurationItem,
  ConfigurationTabItem,
  ConfigurationTabItemCount,
} from '@pages/ConfigurationList/types';
import CONFIG from '@settings/config';
import { SortingState } from '@tanstack/react-table';
import { paths } from 'paths';
import { EcdList } from './Ecds/EcdList';
import { OrganizationList } from './Organizations/OrganizationList';
import { SiteList } from './Sites/SiteList';
import { UserList } from './Users/UserList';

const tabs: ConfigurationTabItem[] = [
  {
    id: ConfigurationItem.SITES,
    label: 'Sites',
  },
  {
    id: ConfigurationItem.ECDS,
    label: 'ECDs',
  },
  {
    id: ConfigurationItem.ORGANIZATIONS,
    label: 'Organizations',
  },
  {
    id: ConfigurationItem.USERS,
    label: 'Users',
  },
];

const addButtons = {
  [ConfigurationItem.SITES]: {
    addNewPath: paths.configurationSiteNew,
    addNewText: 'Create site',
  },
  [ConfigurationItem.ECDS]: {
    addNewPath: '',
    addNewText: '',
  },
  [ConfigurationItem.ORGANIZATIONS]: {
    addNewPath: paths.configurationOrganizationNew,
    addNewText: 'Create organization',
  },
  [ConfigurationItem.USERS]: {
    addNewPath: paths.configurationUserNew,
    addNewText: 'Create user',
  },
};

const getSortingDefaults = (item: ConfigurationItem): SortingState => {
  if (item === ConfigurationItem.SITES) {
    return [{ id: 'name', desc: false }];
  }
  if (item === ConfigurationItem.ECDS) {
    return [{ id: 'id', desc: false }];
  }
  if (item === ConfigurationItem.ORGANIZATIONS) {
    return [{ id: 'name', desc: false }];
  }
  if (item === ConfigurationItem.USERS) {
    return [{ id: 'last_name', desc: false }];
  }
  return [{ id: 'name', desc: false }];
};

type ConfigurationListWithTabsProps = {
  children: ReactNode;
  selectedItem: ConfigurationItem;
  addNewPath: string | undefined;
  addNewText: string | undefined;
  search: Nullable<string>;
  isTabCountsLoading: boolean;
  tabCounts: ConfigurationTabItemCount[];
  onSearchChange: (value: string) => void;
  onSetSelectedItem: (value: ConfigurationItem) => void;
};

const ConfigurationListWithTabs = ({
  children,
  selectedItem,
  addNewPath,
  addNewText,
  search,
  isTabCountsLoading,
  tabCounts,
  onSearchChange,
  onSetSelectedItem,
}: ConfigurationListWithTabsProps) => {
  const { hasRole } = useCurrentRole();
  const redirect = useRedirect();

  return (
    <Grid>
      <Grid container alignItems="center" rowSpacing={2} mb={2} mx={2}>
        <Grid item>
          {tabs && (
            <Box mr={2}>
              <ConfigurationTabs
                isLoading={isTabCountsLoading}
                items={tabs}
                itemsCount={tabCounts}
                selectedTab={selectedItem}
                onHandleChange={onSetSelectedItem}
              />
            </Box>
          )}
        </Grid>
        <Flex item>
          <SearchInput
            size="small"
            value={search}
            sx={{ maxWidth: 450 }}
            onValueChange={onSearchChange}
          />
          {addNewPath && hasRole(UserRole.ADMIN) && (
            <ButtonAdd size="small" sx={{ ml: 2 }} onClick={() => redirect(() => addNewPath)}>
              {addNewText || 'Add'}
            </ButtonAdd>
          )}
        </Flex>
      </Grid>
      <Grid>{children}</Grid>
    </Grid>
  );
};

type ConfigurationListProps = {
  initSelectedItem?: ConfigurationItem;
  onTabChange?: (value: ConfigurationItem) => void;
};

const getTabCounts = (data: HeadersResponse | undefined): ConfigurationTabItemCount[] => {
  if (!data) {
    return [];
  }

  return [
    {
      id: ConfigurationItem.SITES,
      itemsCount: data.siteCount,
    },
    {
      id: ConfigurationItem.ECDS,
      itemsCount: data.ecdCount,
    },
    {
      id: ConfigurationItem.ORGANIZATIONS,
      itemsCount: data.organizationCount,
    },
    {
      id: ConfigurationItem.USERS,
      itemsCount: data.userCount,
    },
  ];
};

const ConfigurationList = ({
  initSelectedItem = ConfigurationItem.SITES,
  onTabChange,
}: ConfigurationListProps) => {
  const [selectedItem, setSelectedItem] = useState<ConfigurationItem>(initSelectedItem);

  const { data, isLoading } = useGetHeadersQuery(null);

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    setSorting,
    setSearchString,
    setPagination,
  } = useFilters({
    enableUrlMirroring: false,
    enableSorting: false,
    pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE,
    sortingState: getSortingDefaults(initSelectedItem),
  });

  const { addNewPath, addNewText } = addButtons[selectedItem];

  return (
    <ConfigurationListWithTabs
      selectedItem={selectedItem}
      addNewPath={addNewPath}
      addNewText={addNewText}
      search={searchString}
      isTabCountsLoading={isLoading}
      tabCounts={getTabCounts(data)}
      onSearchChange={(value) => setSearchString(value)}
      onSetSelectedItem={(value) => {
        setSelectedItem(value);
        setSorting(getSortingDefaults(value));
        if (onTabChange) onTabChange(value);
      }}
    >
      {selectedItem === ConfigurationItem.SITES && (
        <SiteList
          buttonText="Detail"
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onClick={(siteId, redirectTo) => redirectTo((path) => path.configurationSite(siteId))}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      )}
      {selectedItem === ConfigurationItem.ECDS && (
        <EcdList
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      )}
      {selectedItem === ConfigurationItem.ORGANIZATIONS && (
        <OrganizationList
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      )}
      {selectedItem === ConfigurationItem.USERS && (
        <UserList
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      )}
    </ConfigurationListWithTabs>
  );
};

export default ConfigurationList;
