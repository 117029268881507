import { SelectOptions, useGetSitesAllForSelectQuery } from '@apiRtk/sites';
import { useGetUserQuery } from '@apiRtk/users';
import { UserDto } from '@appTypes/models/user.dto';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';

const renderSiteSelectItem = (option: SelectOptions[0]) => {
  const { name } = option;

  return (
    <CenterX gap={1} justifyItems="space-between">
      <Box>
        <Typography>{name || '-'}</Typography>
      </Box>
    </CenterX>
  );
};

type SiteSelectProps = {
  userId: number;
  name: string;
  control: Control<any>;
  setValue: any;
  required?: boolean;
};

const SiteSelect = ({ userId, control, setValue, required, name }: SiteSelectProps) => {
  const { data: sitesData, isLoading } = useGetSitesAllForSelectQuery();
  const { data: userData, isLoading: isUserLoading } = useGetUserQuery(userId);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label="Search site" />
  );

  if (isLoading || isUserLoading || !sitesData || !userData) {
    return <Spinner size={15} />;
  }

  const getFilteredOptions = (sites: SelectOptions, user: UserDto): SelectOptions => {
    const sitesToFilter = user.sites;
    return sites.filter(
      (site) => !sitesToFilter.some((filteredSite) => filteredSite.id === site.id),
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={{ required }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={getFilteredOptions(sitesData, userData)}
          onChange={(_, val) => {
            setValue(name, val?.id || null, { shouldValidate: true });
          }}
          onBlur={() => {
            if (!field.value) {
              setValue(name, null, { shouldValidate: true });
            }
          }}
          value={sitesData.find((profile) => profile.id === field.value) || null}
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => renderInput(params)}
          renderOption={(props, siteData) => {
            const { key, ...rest } = props;
            return (
              <li key={key} {...rest}>
                {renderSiteSelectItem(siteData)}
              </li>
            );
          }}
        />
      )}
    />
  );
};

export default SiteSelect;
