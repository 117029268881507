import { Tooltip } from '@components/Tooltip/Tooltip';
import { ReactComponent as IconInfo } from '@icons/info.svg';

const cardTooltipText = {
  systemStatus: 'The status of your energy system in real time.',
  returnOnInvestment:
    'We calculate your financial benefit by comparing the performance of your site with and without the Wattstor system. We take into account energy and non commodity cost savings plus energy export revenue (if applicable)',
  avoidedEmmisions:
    'We calculate the avoided emissions for the total renewable energy you generate, as well as the renewable energy you consume yourself',
  batteryHealth:
    'To keep track of your battery health and performance, we calculate the cycles per day, total cycles, as well as the total charge and discharge over time.',
  consumptionMix:
    'An overview of the proportion of generated renewable energy and grid energy your site is consuming over time. We are aware that there is an issue with the calculation of total consumption and we are working on fixing it',
};

export const CardTooltip = ({ textKey }: { textKey: keyof typeof cardTooltipText }) => (
  <Tooltip trigger={<IconInfo />} placement="top">
    {cardTooltipText[textKey]}
  </Tooltip>
);
